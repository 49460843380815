import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IntlMessages from "../../../../util/IntlMessages";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import CustomButton from "../../customComponents/button/CustomButton"
import EproDialog from "../../customComponents/dialog/EproDialog";

const useStyles = theme => ({
    eproLabel:{
        margin:"15px 0px 15px 0px",
    },
    labelLink:{
        color:"#B73110",
        '&:hover':{
            color:"#DEA79A",
            textDecoration:"none"
        }
    }
});

class CompanyInformation extends Component {
    constructor(props) {
        super(props);
        this.state ={
            companyName:"",
            addressLineOne:"",
            addressLineTwo:"",
            zipcode:"",
            city:"",
            country:"",
            openCancelAlert:false
        }
    }
    componentDidMount() {
        if(this.props.info && this.props.info.companyInformation){
            this.setState({
                companyName:this.props.info.companyInformation.company_name,
                addressLineOne:this.props.info.companyInformation.address_line_one,
                addressLineTwo:this.props.info.companyInformation.address_line_two,
                zipcode:this.props.info.companyInformation.zipcode,
                city:this.props.info.companyInformation.city,
                country:this.props.info.companyInformation.country
            })
        }
    }
    handleChange = (event) =>{
        let id = event.target.id
        this.setState({
            [id]:event.target.value
        })
    }
    handleSaveCompanyInfo = () =>{
        const {companyName,addressLineOne,addressLineTwo,zipcode,city,country} = this.state
        this.props.updateCompanyInfo(companyName,addressLineOne,addressLineTwo,zipcode,city,country)
    }
    openCancelAlert = () =>{
        this.setState({
            openCancelAlert: true
        })
    }
    handleCancelSubscription = () =>{
        this.props.cancelSubscription()
        this.handleClose()
    }
    handleClose = () =>{
        this.setState({
            openCancelAlert: false
        })
    }

    render() {
        const {classes} = this.props
        return (
            <div>
                <Grid container justify="flex-end" spacing={3}>
                    {/* <Grid item md={10}></Grid> */}
                    <Grid item justify="flex-end" container md={12}>
                        <CustomButton color="red" onClick={this.openCancelAlert}>{<IntlMessages id="_ECLICKSPRO_CANCEL_CURRENT_PACKAGE" /> }</CustomButton>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <div>
                            <TextField
                                // autoFocus
                                margin="dense"
                                id="companyName"
                                label={<IntlMessages id="_ECLICKSPRO_COMPANY_NAME" /> }
                                type="text"
                                value={this.state.companyName}
                                fullWidth
                                // error={this.state.mollieApiKey === ""}
                                // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                                onChange={this.handleChange}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <TextField
                                // autoFocus
                                margin="dense"
                                id="addressLineOne"
                                label={<IntlMessages id="_ECLICKSPRO_ADDRESS_LINE_ONE" /> }
                                type="text"
                                value={this.state.addressLineOne}
                                fullWidth
                                // error={this.state.mollieApiKey === ""}
                                // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                                onChange={this.handleChange}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <TextField
                                // autoFocus
                                margin="dense"
                                id="addressLineTwo"
                                label={<IntlMessages id="_ECLICKSPRO_ADDRESS_LINE_TWO" /> }
                                type="text"
                                value={this.state.addressLineTwo}
                                fullWidth
                                // error={this.state.mollieApiKey === ""}
                                // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                                onChange={this.handleChange}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <div>
                            <TextField
                                // autoFocus
                                margin="dense"
                                id="zipcode"
                                label={<IntlMessages id="_ECLICKSPRO_ZIPCODE" /> }
                                type="text"
                                value={this.state.zipcode}
                                fullWidth
                                // error={this.state.mollieApiKey === ""}
                                // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                                onChange={this.handleChange}
                            />

                            {/*<label className={classes.eproLabel}>*/}
                            {/*    <a className={classes.labelLink} href="https://www.mollie.com/en" target="_blank">{<IntlMessages id="_ECLICKSPRO_MOLLIE_PLEASE_ACTIVE_YOUR_PAYMENT_SETTINGS_ON_MOLLIE"/>}</a>*/}
                            {/*</label>*/}
                        </div>
                    </Grid>

                    <Grid item xs={2}>
                        <div>
                            <TextField
                                // autoFocus
                                margin="dense"
                                id="city"
                                label={<IntlMessages id="_ECLICKSPRO_CITY" /> }
                                type="text"
                                value={this.state.city}
                                fullWidth
                                // error={this.state.mollieApiKey === ""}
                                // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                                onChange={this.handleChange}
                            />

                            {/*<label className={classes.eproLabel}>*/}
                            {/*    <a className={classes.labelLink} href="https://www.mollie.com/en" target="_blank">{<IntlMessages id="_ECLICKSPRO_MOLLIE_PLEASE_ACTIVE_YOUR_PAYMENT_SETTINGS_ON_MOLLIE"/>}</a>*/}
                            {/*</label>*/}
                        </div>
                    </Grid>

                    <Grid item xs={4}>
                        <div>
                            <TextField
                                // autoFocus
                                margin="dense"
                                id="country"
                                label={<IntlMessages id="_ECLICKSPRO_COUNTRY" /> }
                                type="text"
                                value={this.state.country}
                                fullWidth
                                // error={this.state.mollieApiKey === ""}
                                // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                                onChange={this.handleChange}
                            />

                            {/*<label className={classes.eproLabel}>*/}
                            {/*    <a className={classes.labelLink} href="https://www.mollie.com/en" target="_blank">{<IntlMessages id="_ECLICKSPRO_MOLLIE_PLEASE_ACTIVE_YOUR_PAYMENT_SETTINGS_ON_MOLLIE"/>}</a>*/}
                            {/*</label>*/}
                        </div>
                    </Grid>

                    <Grid item xs={4}>
                        <div>
                            <Button
                                variant="contained"
                                className="jr-btn bg-success text-white"
                                style={{ marginTop: "18px" }}
                                onClick={this.handleSaveCompanyInfo}
                            >
                                <SaveIcon fontSize="large" />
                            </Button>
                        </div>
                    </Grid>

                </Grid>

            {/*    dialog*/}

            <EproDialog
                open={this.state.openCancelAlert}
                maxWidth = {"sm"}
                fullWidth={true }
                title={"_ECLICKSPRO_ALERT"}
                handleClose = {this.handleClose}
                handleYes = {this.handleCancelSubscription}
                handleNo = {this.handleClose}
                actions = {['yes','no']}
            >
                <IntlMessages id={"_ECLICKSPRO_CANCEL_SUBSCRIPTION_MSG"}/>
                {/**/}

            </EproDialog>
            </div>
        );
    }
}

export default CompanyInformation;